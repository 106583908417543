import routerOptions0 from "/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}